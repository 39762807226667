import { DirectoryType } from '../../../../graphql/generated';
import { SupportedDirectoryType } from '../../../../interfaces/supported-directory-type';
import { useFeature } from '../../../../utils/feature-flags';

/**
 * A hooks that filters directories based on their feature flag
 */
export const useEnabledDirectories = (
  providers: readonly SupportedDirectoryType[],
) => {
  const oktaAdminPortalEnabledForDrata = useFeature(
    'oktaAdminPortalEnabledForDrata',
  );
  const isHibobEnabled = useFeature('hibobAdminPortal');
  const isWorkdayEnabled = useFeature('workdayAdminPortal');
  const isFourthHrEnabled = useFeature('fourthHrAdminPortal');
  const isBreatheHrEnabled = useFeature('breatheHrAdminPortal');
  const isPeopleHrEnabled = useFeature('peopleHrAdminPortal');
  const isRipplingEnabled = useFeature('ripplingAdminPortal');
  const isRipplingScimEnabled = useFeature('ripplingScimAdminPortal');

  const enabledDirectoriesMap: {
    [k in SupportedDirectoryType]?: boolean;
  } = {
    [DirectoryType.OktaScimv2_0]: oktaAdminPortalEnabledForDrata,
    [DirectoryType.Hibob]: isHibobEnabled,
    [DirectoryType.Workday]: isWorkdayEnabled,
    [DirectoryType.FourthHr]: isFourthHrEnabled,
    [DirectoryType.BreatheHr]: isBreatheHrEnabled,
    [DirectoryType.PeopleHr]: isPeopleHrEnabled,
    [DirectoryType.Rippling]: isRipplingEnabled,
    [DirectoryType.RipplingScimV2_0]: isRipplingScimEnabled,
  };

  return providers.filter((provider) => {
    if (provider in enabledDirectoriesMap) {
      return enabledDirectoriesMap[provider];
    }
    return true;
  });
};
