import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve selected or created my OneLogin application.">
    <Text>
      Log in to the OneLogin admin dashboard and select the "Applications" tab
      at the top. Select "Add App".
    </Text>

    <Img
      className="my-8"
      height={1196}
      src="/images/c5f77445-e09f-4e21-8911-a240e6deee26.png"
      width={2136}
    />

    <Text>
      Search for "scim" in the text field and select the Application with type
      "SCIM Provisioner with SAML (SCIM V2 Enterprise)".
    </Text>

    <Img
      className="my-8"
      height={1248}
      src="/images/a47d721e-db6f-490b-b3e0-9ac284bd3464.png"
      width={2304}
    />

    <Text>
      Give your Application a descriptive Display Name and hit "Save".
    </Text>

    <Img
      className="my-8"
      height={1430}
      src="/images/4dd4042a-847e-4fde-b835-750a2f0c5644.png"
      width={2076}
    />
  </StepContent>
);

export const selectOrCreateOneLoginApplication: StepData = {
  title: 'Select or Create OneLogin Application',
  component: Component,
};
