import React, { VFC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncStepData } from '../../utils/generate-dsync-step-data';
import { generateApiKeyInBreatheHr } from './1-generate-api-key-in-breathe-hr';
import { uploadBreatheHrApiKey } from './2-upload-breathe-hr-api-key';
import { setUpAttributeMapping } from './3-set-up-attribute-mapping';

interface BreatheHrStepsProps {}

export type BreatheHrFormData = DsyncFormData<'breatheHrApiKey'>;

const stepsData: StepData<BreatheHrFormData>[] = generateDsyncStepData([
  generateApiKeyInBreatheHr,
  uploadBreatheHrApiKey,
  setUpAttributeMapping,
]);

export const BreatheHrSteps: VFC<Readonly<BreatheHrStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(stepsData);
  const stepRoutes = useDsyncStepRoutes<BreatheHrFormData>(enabledSteps);

  return (
    <DsyncStepNavigation stepsData={enabledSteps}>
      {stepRoutes}
    </DsyncStepNavigation>
  );
};
