import React, { VFC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncStepData } from '../../utils/generate-dsync-step-data';
import { generateApiKeyInBambooHr } from './1-generate-api-key-in-bamboo-hr';
import { uploadBambooHrApiKeyAndSubdomain } from './2-upload-bamboo-hr-api-key-and-domain';
import { setUpAttributeMapping } from './3-set-up-attribute-mapping';

interface BambooHrStepsProps {}

export type BambooHrFormData = DsyncFormData<
  'bamboo_hr_api_key' | 'bamboo_hr_subdomain'
>;

const stepsData: StepData<BambooHrFormData>[] = generateDsyncStepData([
  generateApiKeyInBambooHr,
  uploadBambooHrApiKeyAndSubdomain,
  setUpAttributeMapping,
]);

export const BambooHrSteps: VFC<Readonly<BambooHrStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(stepsData);
  const stepRoutes = useDsyncStepRoutes<BambooHrFormData>(enabledSteps);

  return (
    <DsyncStepNavigation stepsData={enabledSteps}>
      {stepRoutes}
    </DsyncStepNavigation>
  );
};
