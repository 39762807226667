import { Breadcrumb } from '@workos-inc/component-library';
import React, { VFC } from 'react';
import { Link } from '../../../components/link/internal-link';
import { usePortalSession } from '../../../components/portal-session-provider';

interface AuditLogsBreadcrumbProps {
  auditLogId?: string;
}

export const AuditLogsBreadcrumb: VFC<AuditLogsBreadcrumbProps> = ({
  auditLogId,
}) => {
  const { appName } = usePortalSession();

  return (
    <Breadcrumb>
      <Breadcrumb.Item asChild>
        <Link href="#">{appName}</Link>
      </Breadcrumb.Item>

      <Breadcrumb.Separator />

      <Breadcrumb.Item asChild>
        <Link href="/audit-logs/events">Audit Logs</Link>
      </Breadcrumb.Item>

      {auditLogId && (
        <>
          <Breadcrumb.Separator />

          <Breadcrumb.Item isCurrent>{auditLogId}</Breadcrumb.Item>
        </>
      )}
    </Breadcrumb>
  );
};
