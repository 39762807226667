import classnames from 'classnames';
import React, { FC } from 'react';

export interface OlProps {
  children?: React.ReactNode;
  className?: string;
}

export const Ol: FC<Readonly<OlProps>> = ({ children, className }) => (
  <ol
    className={classnames(
      'list-inside list-decimal space-y-3 text-sm text-gray-lightmode-300',
      className,
    )}
  >
    {children}
  </ol>
);
