import { Text } from '@workos-inc/component-library';
import React, { VFC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { BreatheHrFormData } from './breathe-hr-steps';

const Component: VFC = () => (
  <StepContent confirmLabel="I've generated my Breathe HR API key.">
    <Text>
      If you are an admin of your Breathe HR instance, log in and select the
      "Configure" option in the top menu of the Breathe HR console, then select
      "Settings" in the drop down menu.
    </Text>

    <Img
      className="my-8"
      height={1438}
      src="/images/3defb6ef-fe7d-458f-8639-d0410fc11f51.png"
      width={2056}
    />

    <Text>Under "Integrations", select "API Setup".</Text>

    <Img
      className="my-8"
      height={1484}
      src="/images/d6c8d0e9-1d71-428e-b368-4bcd5240432c.png"
      width={2074}
    />

    <Text>Select "Enable API".</Text>

    <Img
      className="my-8"
      height={1304}
      src="/images/890c4a8e-4217-433f-b67f-037dda40a7c5.png"
      width={2276}
    />

    <Text>
      Verify that you'd like to enable the API to access user information.
    </Text>

    <Img
      className="my-8"
      height={1304}
      src="/images/e6623c61-c520-4636-a029-c91fb2b5a33f.png"
      width={2276}
    />

    <Text>
      Save the generated production key. You'll upload this key in the next
      step.
    </Text>

    <Img
      className="my-8"
      height={1228}
      src="/images/d66a8ba2-bcf6-44bf-a714-e08164cdf632.png"
      width={2216}
    />
  </StepContent>
);

export const generateApiKeyInBreatheHr: StepData<BreatheHrFormData> = {
  title: 'Generate API Key in Breathe HR',
  component: Component,
};
