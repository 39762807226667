import { Button, Label, Select, Text } from '@workos-inc/component-library';
import React, { useEffect, VFC } from 'react';
import { Card } from '../../../../components/card';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { useDsyncStore } from '../../dsync-store-provider';
import { GoogleWorkspaceFormData } from './google-workspace-steps';

const Component: VFC = () => {
  const { appName, domains } = usePortalSession();

  const { formData, isLoadingNextStep, setFormData } =
    useStepsStore<GoogleWorkspaceFormData>();

  const { directory, updateDirectory } = useDsyncStore();

  const initialDomain =
    domains.find((domain) => domain === directory.primaryDomain?.domain) ||
    domains[0];

  useEffect(() => {
    if (!formData.domain) {
      setFormData({
        domain: initialDomain,
      });
    }
  }, [formData.domain, initialDomain, setFormData]);

  return (
    <>
      <Text>
        To connect your Google Workspace directory to {appName} you will need a
        user with sufficient privileges to allow access to:
      </Text>

      <ul className="my-4 list-inside list-disc space-y-3 text-gray-lightmode-300">
        <li>View your {formData.domain} email address</li>
        <li>View groups on your domain</li>
        <li>View users on your domain</li>
      </ul>

      <Text>
        Click <strong>Allow Access</strong> to continue.
      </Text>

      <Card>
        <Label className="mb-2" htmlFor="google-domain">
          Your Google Workspace Domain
        </Label>

        <Select
          name="google-domain"
          onValueChange={(value) => {
            setFormData({ domain: value });

            void updateDirectory({ id: directory.id, domain: value });
          }}
          value={formData.domain}
        >
          <Select.Trigger id="google-domain" />

          <Select.Content>
            <Select.Item disabled value="placeholder">
              <Select.ItemText>
                Select your Google Workspace Domain
              </Select.ItemText>
            </Select.Item>

            {domains.map((domain) => (
              <Select.Item key={domain} value={domain}>
                <Select.ItemText>{domain}</Select.ItemText>
                <Select.ItemIndicator />
              </Select.Item>
            ))}
          </Select.Content>
        </Select>

        <div className="mt-2 flex justify-end">
          <Button
            className="self-end"
            isLoading={isLoadingNextStep}
            onClick={() => {
              window.location.href = directory.setup_url || '';
            }}
          >
            Allow Access
          </Button>
        </div>
      </Card>
    </>
  );
};

export const allowAccessToGoogleWorkspace: StepData<GoogleWorkspaceFormData> = {
  title: 'Allow Access to Google Workspace',
  component: Component,
  formKeys: ['domain'],
};
