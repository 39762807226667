import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've created the Security Group.">
    <Text>
      Create a new security group in Workday. Set the Type of Tenanted Security
      Group to Integration System Security Group (Unconstrained). Then add a
      name for the Security Group and select OK.
    </Text>

    <Img
      className="my-8"
      height={1074}
      src="/images/eae9389d-1ce6-4237-a8fb-d8007df877b4.png"
      width={1380}
    />

    <Text>
      Next, for Integration System Users, add the integration system user you
      created in the previous step, and select OK.
    </Text>

    <Img
      className="my-8"
      height={816}
      src="/images/af6d1ff5-dd98-4a93-855c-ae3bcf890c6b.png"
      width={1672}
    />
  </StepContent>
);

export const createASecurityGroup: StepData = {
  title: 'Create a Security Group',
  component: Component,
};
