import { InputGroup, Text } from '@workos-inc/component-library';
import React, { VFC } from 'react';
import { Card } from '../../../../components/card';
import { Form } from '../../../../components/form';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { useStepsStore } from '../../../../shared/step-navigation/steps-store-provider';
import { PeopleHrFormData } from './people-hr-steps';

const Component: VFC = () => {
  const {
    formData,
    formErrors,
    handleInputChange,
    handleNextStep,
    isLoadingNextStep,
  } = useStepsStore<PeopleHrFormData>();

  return (
    <>
      <Text>Upload the API key you generated in your last step.</Text>

      <Card>
        <Form
          disabled={!formData.peopleHrApiKey}
          isLoading={isLoadingNextStep}
          isUpdate={!!formErrors.peopleHrApiKey}
          onSubmit={handleNextStep}
        >
          <InputGroup
            autoFocus
            error={formErrors.peopleHrApiKey ?? undefined}
            id="peopleHrApiKey"
            label="1. Access People HR API Key"
            name="peopleHrApiKey"
            onChange={handleInputChange}
            placeholder=""
            type="password"
            value={formData.peopleHrApiKey ?? undefined}
          />
        </Form>
      </Card>
    </>
  );
};

export const uploadAccessPeopleHrApiKey: StepData<PeopleHrFormData> = {
  title: 'Upload Access People HR API Key',
  component: Component,
  formKeys: ['peopleHrApiKey'],
};
