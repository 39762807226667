import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Topnav } from '../../../components/topnav';
import { ConfiguredSsoConnectionPage } from '../pages/configured-sso-connection-page/configured-sso-connection-page';
import { SsoStoreProvider } from '../sso-store-provider';
import { SsoSteps } from '../steps/sso-steps';
import { SsoRedirector } from './sso-redirector';

interface SsoRouterProps {}

export const SsoRouter: FC<Readonly<SsoRouterProps>> = () => (
  <SsoStoreProvider>
    <Topnav />

    <Routes>
      <Route element={<SsoRedirector />} path="/">
        <Route element={<div>Provider Selection</div>} path="/new" />
        <Route element={<SsoSteps />} path="/configure/*" />

        <Route
          element={<div>Connection Session</div>}
          path="/session/:sessionId"
        />

        <Route
          element={<ConfiguredSsoConnectionPage />}
          path="/:connectionId"
        />
      </Route>
    </Routes>
  </SsoStoreProvider>
);
