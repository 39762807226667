import React, { VFC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncStepData } from '../../utils/generate-dsync-step-data';
import { generateApiKeyInAccessPeopleHr } from './1-generate-api-key-in-access-people-hr';
import { uploadAccessPeopleHrApiKey } from './2-upload-access-people-hr-api-key';
import { setUpAttributeMapping } from './3-set-up-attribute-mapping';

interface PeopleHrStepsProps {}

export type PeopleHrFormData = DsyncFormData<'peopleHrApiKey'>;

const stepsData: StepData<PeopleHrFormData>[] = generateDsyncStepData([
  generateApiKeyInAccessPeopleHr,
  uploadAccessPeopleHrApiKey,
  setUpAttributeMapping,
]);

export const PeopleHrSteps: VFC<Readonly<PeopleHrStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(stepsData);
  const stepRoutes = useDsyncStepRoutes<PeopleHrFormData>(enabledSteps);

  return (
    <DsyncStepNavigation stepsData={enabledSteps}>
      {stepRoutes}
    </DsyncStepNavigation>
  );
};
