import { Text } from '@workos-inc/component-library';
import classNames from 'classnames';
import React, { FC, ReactElement, VFC } from 'react';
import { IconProps } from 'react-feather';

interface JsonSchemaNavItemProps {
  title: string;
  selected: boolean;
  subtitle: string;
  icon: ReactElement<IconProps>;
  onSelect?: () => void;
}

export const JsonSchemaNavItem: VFC<JsonSchemaNavItemProps> = ({
  title,
  subtitle,
  selected,
  icon,
  onSelect: handleOnSelect,
}) => (
  <li>
    <button
      className={classNames(
        // eslint-disable-next-line max-len
        'w-full p-3 text-sm text-left rounded-md cursor-pointer transition-colors duration-200 ease-in-out max-w-xs hover:bg-[#F2F2F3] dark:hover:bg-gray-darkmode-100',
        selected && 'bg-[#F2F2F3] dark:bg-gray-darkmode-100',
      )}
      onClick={handleOnSelect}
    >
      <div className="inline-flex w-full items-center gap-1 text-gray-lightmode-400 dark:text-gray-darkmode-400">
        {React.cloneElement(icon, {
          ...icon.props,
          size: 14,
          className: classNames(
            'shrink-0 transition-opacity',
            !selected && 'opacity-50',
            icon.props.className,
          ),
        })}
        <Text
          inheritColor
          as="p"
          className="truncate"
          size="small"
          weight={selected ? 'medium' : 'normal'}
        >
          {title}
        </Text>
      </div>
      <Text as="p" className="truncate" size="small">
        {subtitle}
      </Text>
    </button>
  </li>
);

export const JsonSchemaNav: FC = ({ children }) => (
  <nav>
    <ul className="flex flex-col gap-y-0.5">{children}</ul>
  </nav>
);
