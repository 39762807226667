import { Button, Text } from '@workos-inc/component-library';
import { parse } from 'date-fns';
import React, { FC, useState } from 'react';
import { Portal_CreateAuditLogExportInput } from '../../../../../graphql/generated';
import { AuditLogExportDetails } from '../../hooks/use-audit-log-export';
import { DatasetPicker } from './dataset-picker';
import { DateRangePicker } from './date-range-picker';
import { DataSet, ExportDate, ExportFilters } from './types';

interface IdleStateProps extends Pick<AuditLogExportDetails, 'state'> {
  filters: [string, string][];
  onGenerate: (input: Portal_CreateAuditLogExportInput) => void;
  onCancel: () => void;
}
export const IdleState: FC<IdleStateProps> = ({
  state,
  filters,
  onGenerate,
  onCancel,
}) => {
  const filtersWithoutDate = filters.filter(([key]) => key !== 'date');
  const hasFilters = filtersWithoutDate.length > 0;
  const filteredDate = filters.find(([key]) => key === 'date')?.[1] ?? '';
  const [dateRange, setDateRange] = useState(filteredDate);
  const [dataset, setDataset] = useState<DataSet>(
    hasFilters ? DataSet.Filtered : DataSet.All,
  );

  return (
    <>
      <Text as="h1" size="large" weight="medium">
        Export to CSV
      </Text>

      <DateRangePicker
        date={dateRange}
        onDateRangeChange={(range) => setDateRange(range)}
      />

      {hasFilters && (
        <DatasetPicker
          dataset={dataset}
          filters={filtersWithoutDate}
          onDatasetChange={(value) => setDataset(value)}
        />
      )}

      <div className="flex gap-x-2">
        <Button
          isLoading={state === 'loading'}
          onClick={() =>
            onGenerate({
              ...getExportDateRange(dateRange),
              ...getExportFilters(dataset, filtersWithoutDate),
            })
          }
        >
          Generate CSV file
        </Button>
        <Button appearance="secondary" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </>
  );
};

const getExportDateRange = (dateRange: string): ExportDate => {
  const [startDate = '', endDate = ''] = dateRange.split(',');

  return {
    rangeStart: new Date(
      parse(startDate, 'yyyy-MM-dd', new Date()),
    ).toISOString(),
    rangeEnd: new Date(parse(endDate, 'yyyy-MM-dd', new Date())).toISOString(),
  };
};

const filterToArray = (value?: string) => value?.split(',');

const getExportFilters = (
  dataset: DataSet,
  filters: [string, string][],
): ExportFilters => {
  if (dataset === DataSet.All) {
    return {};
  }

  return {
    actions: filterToArray(filters.find(([key]) => key === 'event')?.[1]),
    targets: filterToArray(filters.find(([key]) => key === 'target')?.[1]),
    actors: filterToArray(filters.find(([key]) => key === 'actor')?.[1]),
  };
};
