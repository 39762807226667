import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've filled out the basic app info and clicked Create App.">
    <Text>
      If you are an admin of your Rippling instance, log in and select the
      Custom App option in the menu under the Identity Management category.
    </Text>

    <Img
      className="my-8"
      height={939}
      src="/images/2851bbb9-a486-4e51-90c1-6265959cb336.png"
      width={1387}
    />
    <Text>Select "Create New App" in the Custom App page.</Text>

    <Img
      className="my-8"
      height={939}
      src="/images/bd2b1fba-2433-4fc2-b20b-7a0ca712dd20.png"
      width={1387}
    />

    <Text>
      Fill out the application's name, upload an image for the logo, and check
      the "SCIM Provisioning" box. Click "Create App" and the page will update
      with more option fields regarding SCIM setup.
    </Text>

    <Img
      className="my-8"
      height={939}
      src="/images/30904e12-3522-4c21-b663-9e700fa06ea6.png"
      width={1387}
    />
  </StepContent>
);

export const createARipplingScimApplication: StepData = {
  title: 'Create a Rippling SCIM application',
  component: Component,
};
