import React, { VFC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncStepData } from '../../utils/generate-dsync-step-data';
import { createAServiceUserInHibob } from './1-create-a-service-user-in-hibob';
import { uploadHibobServiceUserIdAndToken } from './2-upload-hibob-service-user-id-and-token';
import { setUpAttributeMapping } from './3-set-up-attribute-mapping';

interface HibobStepsProps {}

export type HibobFormData = DsyncFormData<
  'bob_api_token' | 'bob_service_user_id'
>;

const stepsData: StepData<HibobFormData>[] = generateDsyncStepData([
  createAServiceUserInHibob,
  uploadHibobServiceUserIdAndToken,
  setUpAttributeMapping,
]);

export const HibobSteps: VFC<Readonly<HibobStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(stepsData);
  const stepRoutes = useDsyncStepRoutes<HibobFormData>(enabledSteps);

  return (
    <DsyncStepNavigation stepsData={enabledSteps}>
      {stepRoutes}
    </DsyncStepNavigation>
  );
};
