import { Text } from '@workos-inc/component-library';
import * as React from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MapAttributesStep } from '../../map-attributes-step/map-attributes-step';

const Component = () => (
  <MapAttributesStep>
    <Text>
      There are additional attributes needed by the application for it to fully
      function. Note that basic attributes like a user's name or email are
      already mapped, and so are not present below.
    </Text>

    <Text>
      To see the names of attributes that you can map from your Directory
      Provider, return to the "Provisioning" tab in your Enterprise Okta Admin
      Panel and click on "To App" in the sidebar.
    </Text>

    <Img
      priority
      height={1230}
      src="/admin-portal/directory-sync/okta/v1/okta-map-attributes-1.png"
      width={2050}
    />

    <Text>
      Scroll down to the section labeled "[Your Application's Name] Attribute
      Mappings" where you should see a table containing attributes available to
      the application. Any of the camel-cased values can be used as a value for
      an application attribute.
    </Text>

    <Text>
      For example, if you wanted to use the value of "Username" for an attribute
      mapping, you would enter "userName" as the "Directory Provider Value".
    </Text>

    <Img
      priority
      height={1360}
      src="/admin-portal/directory-sync/okta/v1/okta-map-attributes-2.png"
      width={1662}
    />

    <Text>
      Map attributes from your Directory Provider's responses to the attributes
      shown below.
    </Text>
  </MapAttributesStep>
);

export const setupAttributeMapping: StepData = {
  title: 'Set up Attribute Mapping',
  customAttributesStep: true,
  component: Component,
};
