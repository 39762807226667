import React, { VFC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncStepData } from '../../utils/generate-dsync-step-data';
import { setUpAttributeMapping } from './1-step-up-attribute-mapping';
import { allowAccessToGoogleWorkspace } from './2-allow-access-to-google-workspace';

interface GoogleWorkspaceStepsProps {}

export type GoogleWorkspaceFormData = DsyncFormData<'domain'>;

const stepsData: StepData<GoogleWorkspaceFormData>[] = generateDsyncStepData([
  setUpAttributeMapping,
  allowAccessToGoogleWorkspace,
]);

export const GoogleWorkspaceSteps: VFC<
  Readonly<GoogleWorkspaceStepsProps>
> = () => {
  const enabledSteps = useEnabledDsyncSteps(stepsData);
  const stepRoutes = useDsyncStepRoutes<GoogleWorkspaceFormData>(enabledSteps);

  return (
    <DsyncStepNavigation stepsData={enabledSteps}>
      {stepRoutes}
    </DsyncStepNavigation>
  );
};
