import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { Img } from '../../../../components/image';
import { CopyInput } from '../../../../components/inputs';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';
import { useDsyncStore } from '../../dsync-store-provider';

const Component: FC = () => {
  const { directory } = useDsyncStore();

  return (
    <StepContent confirmLabel="I've entered and saved the Provisioning settings.">
      <Text>
        CyberArk supports SCIM provisioning in the context of a SAML app. The
        usual set up is to set up your SAML app first. Log in to the CyberArk
        Identity Admin Portal and select "Web Apps" from the left-side
        navigation. Then, select or create your SAML app.
      </Text>

      <Text>
        In the SAML app, open the "Provisioning" tab, and select the box to
        "Enable provisioning for this application".
      </Text>

      <Img
        className="my-8"
        height={1030}
        src="/images/83b18d01-90d6-4cf2-8866-84c2046cd1f5.png"
        width={1647}
      />

      <Text>Click "Yes" in the confirmation modal.</Text>

      <Img
        className="my-8"
        height={2060}
        src="/images/38f35897-4fb6-47d0-9445-53b0405b8809.png"
        width={3294}
      />
      <Text>
        Copy and paste the endpoint into the "SCIM Service URL" field.
      </Text>

      <CopyInput
        id="directory-endpoint"
        label="Copy this Endpoint"
        value={directory.endpoint}
      />

      <Text>
        Copy and paste the Bearer Token into the "Bearer Token" field.
      </Text>

      <CopyInput
        id="directory-bearer-token"
        label="Copy this Bearer Token"
        type="password"
        value={directory.bearerToken}
      />

      <Img
        className="my-8"
        height={1030}
        src="/images/97264b1b-c36d-427c-9f26-6b31326694cd.png"
        width={1647}
      />
    </StepContent>
  );
};

export const selectOrCreateYourCyberArkApplication: StepData = {
  title: 'Select or create your CyberArk application',
  component: Component,
};
