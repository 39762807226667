import { useAdapterStatus } from '@flopflip/react-broadcast';
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useFeature } from '../../../utils/feature-flags';
import { usePortalSession } from '../portal-session-provider';

interface HeadProps {}

export const Head: FC<Readonly<HeadProps>> = () => {
  const { faviconUrl, appName } = usePortalSession();
  const [title, setTitle] = useState('Admin Portal');
  const hidePoweredByWorkos = useFeature('hidePoweredByWorkos');
  const { isReady } = useAdapterStatus();

  useEffect(() => {
    if (isReady && appName) {
      const titleBrand = !hidePoweredByWorkos ? 'WorkOS' : appName;
      setTitle(`${titleBrand} Admin Portal`);
    }
  }, [appName, isReady, hidePoweredByWorkos]);

  return (
    <Helmet>
      <title>{title}</title>
      <meta content="width=640" name="viewport" />
      <link
        href={
          faviconUrl ?? 'https://workos.imgix.net/brand/v2/favicon@32x32.png'
        }
        rel="shortcut icon"
        type="image/x-icon"
      />
      <link
        href={
          faviconUrl ?? 'https://workos.imgix.net/brand/v2/favicon@256x256.png'
        }
        rel="apple-touch-icon"
      />

      <link
        as="style"
        href="https://fonts.googleapis.com/css?family=Inter:400,500,700&display=auto"
        rel="preload"
      />
      <link
        href="https://fonts.googleapis.com/css?family=Inter:400,500,700&display=auto"
        rel="stylesheet"
      />
    </Helmet>
  );
};
