import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've added an authorized user to both the User and Group Reports.">
    <Text>
      Add the Integration User created in Step 1 as an authorized user of both
      the User and Group Reports. This can be found under the Share tab from
      within a Report.
    </Text>

    <Img
      className="my-8"
      height={1550}
      src="/images/b37ad47e-7431-4426-8118-70350077676f.png"
      width={1360}
    />
  </StepContent>
);

export const addAnAuthorizedUser: StepData = {
  title: 'Add an authorized user',
  component: Component,
};
