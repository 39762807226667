import React, { VFC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { DsyncFormData } from '../../interfaces/dsync-form-data';
import { generateDsyncStepData } from '../../utils/generate-dsync-step-data';
import { uploadFourthHrOrganizationIdUsernameAndPassword } from './1-upload-fourth-hr-organization-id-username-and-password';
import { setUpAttributeMapping } from './2-set-up-attribute-mapping';

interface FourthHrStepsProps {}

export type FourthHrFormData = DsyncFormData<
  'fourthHrOrganizationId' | 'fourthHrUsername' | 'fourthHrPassword'
>;

const stepsData: StepData<FourthHrFormData>[] = generateDsyncStepData([
  uploadFourthHrOrganizationIdUsernameAndPassword,
  setUpAttributeMapping,
]);

export const FourthHrSteps: VFC<Readonly<FourthHrStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(stepsData);
  const stepRoutes = useDsyncStepRoutes<FourthHrFormData>(enabledSteps);

  return (
    <DsyncStepNavigation stepsData={enabledSteps}>
      {stepRoutes}
    </DsyncStepNavigation>
  );
};
