import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve completed my assignments.">
    <Text>Make sure all attributes previously added are enabled.</Text>

    <Img
      className="my-8"
      height={1109}
      src="/images/15000694-0040-4b4c-8731-9052925987cb.png"
      width={1402}
    />
  </StepContent>
);

export const setUpAttributeMapping: StepData = {
  title: 'Set up Attribute Mapping',
  component: Component,
};
