import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I've completed the role mappings.">
    <Text>
      Roles mapped in the application will be synced as groups. The roles are
      mapped on the Provisioning settings page, by selecting the "Add" button.
    </Text>

    <Img
      className="my-8"
      height={1023}
      src="/images/e782ba16-8085-4684-9295-6eaf1287cb0c.png"
      width={1585}
    />
    <Text>
      In the role mapping modal, select the role you'd like to map, and then
      create a destination group. The name will be what you see as the group
      name in directory sync. All users assigned to that role will be members of
      the mapped group. Select "Done".
    </Text>

    <Img
      className="my-8"
      height={1023}
      src="/images/e5db6660-259c-48c2-8e81-03c8cc950778.png"
      width={1585}
    />

    <Text>
      After the role mapping is completed, click "Save". The SCIM configuration
      part of the setup is complete.
    </Text>
  </StepContent>
);

export const configureTheRoleMappingsInCyberArk: StepData = {
  title: 'Configure the Role Mappings in CyberArk',
  component: Component,
};
