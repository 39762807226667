import classnames from 'classnames';
import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface LinkProps {
  appearance?: 'highlight';
  children?: React.ReactNode;
  className?: string;
  download?: string;
  href: string;
  newTab?: boolean;
}

export const Link: FC<Readonly<LinkProps>> = ({
  href,
  children,
  appearance,
  className,
  newTab = false,
  download,
}) => {
  const classNames = classnames(
    'transition duration-200 ease-in-out',
    {
      'text-primary-darken hover:underline dark:text-primary':
        appearance === 'highlight',
    },
    className,
  );

  return (
    <RouterLink
      className={classNames}
      download={download}
      rel={newTab ? 'noreferrer noopener' : ''}
      target={newTab ? '_blank' : '_self'}
      to={href}
    >
      {children}
    </RouterLink>
  );
};
