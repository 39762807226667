import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve installed the SCIM Connector, deployed the SCIM Connector Files, and enabled Provisioning.">
    <Text>
      There are several prerequisite steps required in PingFederate for this
      integration. First, download and install the{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href={
          'https://docs.pingidentity.com/bundle/pingfederate-scim-connector/page/ulk1563995050657.html'
        }
      >
        PingFederate SCIM Connector
      </ExternalLink>
      .
    </Text>

    <Text>
      Next, deploy the SCIM Connector files to your PingFederate directory
      following{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href={
          'https://docs.pingidentity.com/bundle/pingfederate-scim-connector/page/dcn1563995073633.html'
        }
      >
        the documentation from PingFederate
      </ExternalLink>
      .
    </Text>

    <Text>
      Finally, enable provisioning in PingFederate using the{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href={
          'https://docs.pingidentity.com/bundle/pingfederate-scim-connector/page/xnt1568332743066.html'
        }
      >
        documentation provided by PingFederate
      </ExternalLink>
      .
    </Text>
  </StepContent>
);

export const installTheScimConnectorInPingFederate: StepData = {
  title: 'Install the SCIM Connector in PingFederate',
  component: Component,
};
