import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DirectoryUserFragment } from '../../../../../../graphql/generated';
import { Pagination } from '../../../../../components/pagination';
import { graphql } from '../../../../../utils/graphql';
import {
  PaginationInfo,
  withPagination,
} from '../../../../../utils/pagination';
import { DirectoryUsersTable } from './directory-users-table';

interface DirectoryUsersProps {}

export const DirectoryUsers: FC<Readonly<DirectoryUsersProps>> = () => {
  const { directoryId } = useParams();

  const [before, setBefore] = useState<string | null>();
  const [after, setAfter] = useState<string | null>();
  const [pagination, setPagination] = useState<PaginationInfo>();
  const [users, setUsers] = useState<DirectoryUserFragment[]>();

  const handleNextPage = () => {
    setBefore(pagination?.before);
    setAfter(undefined);
  };

  const handlePreviousPage = () => {
    setAfter(pagination?.after);
    setBefore(undefined);
  };

  useEffect(() => {
    const loadUsers = async (directoryId: string) => {
      const [directoryUsersResponse, pagination] = await withPagination(
        graphql().DirectoryUsers({
          directoryId: String(directoryId),
          before,
          after,
        }),
        (data) => data?.portal_directory.directoryUsers,
      );

      setPagination(pagination);

      if (directoryUsersResponse?.data) {
        setUsers(
          directoryUsersResponse.data.portal_directory.directoryUsers.data,
        );
      }
    };

    if (directoryId) {
      void loadUsers(directoryId);
    }
  }, [after, before, directoryId, setUsers]);

  if (!users) {
    return null;
  }

  return (
    <>
      <DirectoryUsersTable users={users} />

      <Pagination
        hasNext={pagination?.hasNext}
        hasPrevious={pagination?.hasPrevious}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
      />
    </>
  );
};
