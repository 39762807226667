import React, { FC, useEffect, useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { ConnectionState } from '../../../../graphql/generated';
import { useSsoStore } from '../sso-store-provider';

export const SsoRedirector: FC = () => {
  const navigate = useNavigate();
  const onConfigurePage = useMatch('/sso/configure/*');
  const onRootSsoPage = useMatch('/sso');
  const onNewPage = useMatch('/sso/new');

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { connection } = useSsoStore();

  useEffect(() => {
    if (!isInitialLoad && !onRootSsoPage) {
      return;
    }

    if (connection?.state) {
      if (connection?.state === ConnectionState.Active) {
        navigate(`/sso/${connection.id}`, { replace: true });
      } else if (!onConfigurePage) {
        navigate('/sso/configure/1', { replace: true });
      }
    } else if (!onNewPage) {
      navigate('/sso/new', { replace: true });
    }

    if (isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [
    connection?.state,
    connection?.id,
    isInitialLoad,
    onConfigurePage,
    onRootSsoPage,
    onNewPage,
    navigate,
  ]);

  return <Outlet />;
};
