import classnames from 'classnames';
import React, { FC } from 'react';

export interface CenteredPageContentProps {
  children?: React.ReactNode;
  className?: string;
}

export const CenteredPageContent: FC<Readonly<CenteredPageContentProps>> = ({
  children,
  className,
}) => (
  <div className={classnames('mx-auto mt-12 max-w-4xl px-5', className)}>
    {children}
  </div>
);
