import { Text } from '@workos-inc/component-library';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { FC, ReactElement } from 'react';
import { Check } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { StepDataWithPath } from '../interfaces/step-data';
import { useStepsStore } from '../steps-store-provider';

interface SidebarNavItemProps<TFormData> {
  step: StepDataWithPath<TFormData>;
  stepNumber: number;
}

const itemVariants = {
  hidden: { opacity: 0, x: -50 },
  show: { opacity: 1, x: 0 },
};

export const SidebarNavItem = <TFormData,>({
  step,
  stepNumber,
}: SidebarNavItemProps<TFormData>) => {
  const { currentStepNumber } = useStepsStore();
  const navigate = useNavigate();

  const getStepIcon = (stepNumber: number): ReactElement | null => {
    const isCompleted = stepNumber < currentStepNumber;

    // TODO: This will be plugged in with DRGN-119
    // const hasError = stepsWithError.includes(index + 1);

    // if (hasError) {
    //   return (
    //     <Icon className="bg-red-200 text-red-darken">
    //       <X size={10} strokeWidth={4} />
    //     </Icon>
    //   );
    // }

    // if (index + 1 === confirmationStep && stepsWithError.length > 0) {
    //   // don't render a green check for confirmation step if we any errors
    //   return null;
    // }

    if (isCompleted) {
      return (
        <Icon className="bg-green-100 text-green-600">
          <Check data-testid="completed-icon" size={10} strokeWidth={4} />
        </Icon>
      );
    }

    return null;
  };

  const isActive = stepNumber === currentStepNumber;
  const isClickable = stepNumber <= currentStepNumber;

  return (
    <motion.li
      className={classNames(
        {
          'list-none -ml-5 text-gray-lightmode-300': !isActive && isClickable,
          'list-decimal text-primary': isActive && isClickable,
          'list-decimal text-gray-lightmode-200': !isClickable,
        },
        'mb-3 align-middle text-sm',
      )}
      variants={itemVariants}
    >
      <div
        className={classNames({
          'cursor-pointer': isClickable,
          'cursor-auto': !isClickable,
        })}
        onClick={() => isClickable && navigate(step.path)}
      >
        <Text inheritColor as="p">
          {getStepIcon(stepNumber)}
          {step.title}
        </Text>
      </div>
    </motion.li>
  );
};

type IconProps = {
  children?: React.ReactNode;
  className?: string;
};

const Icon: FC<Readonly<IconProps>> = ({ children, className }) => (
  <i
    className={classNames(
      'rounded-full w-4 h-4 inline-flex justify-center items-center leading-4 text-center mr-2',
      className,
    )}
  >
    {children}
  </i>
);
