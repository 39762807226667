import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { StepContent } from '../../../../shared/step-navigation/step-content';

const Component: FC = () => (
  <StepContent confirmLabel="I’ve completed my assignments.">
    <Text>
      NOTE: There are many ways to provision groups in OneLogin. Below is one
      method that we recommend, but other methods can be used.
    </Text>

    <Text>
      In the top navigation, Select "Users" and then "Roles" from the dropdown.
      Select "New Role".
    </Text>

    <Img
      className="my-8"
      height={1392}
      src="/images/ecb269b5-bfe0-41f7-b73f-11a152ac0197.png"
      width={2086}
    />

    <Text>
      Give the Role a name (this will be the name of the group), select the
      appropriate SCIM application, and hit "Save".
    </Text>

    <Img
      className="my-8"
      height={1392}
      src="/images/9924f509-c60a-47b5-ae30-017c361d1b55.png"
      width={2086}
    />

    <Text>
      Click the "Users" tab for the role. Search for any users you'd like to
      assign to that role and hit "Add To Role". Then hit "Save".
    </Text>

    <Img
      className="my-8"
      height={1276}
      src="/images/b4a4be2a-f75f-4dfe-adb9-dea585be0fce.png"
      width={2046}
    />

    <Text>Click "Save" in the next modal to confirm.</Text>

    <Img
      className="my-8"
      height={1276}
      src="/images/0a6ebdb2-3896-4481-8e11-ca9f1a23ecd0.png"
      width={2046}
    />

    <Text>
      Navigate back to your SCIM app and click on the "Rules" tab on the left.
      Then, hit "Add Rule".
    </Text>

    <Img
      className="my-8"
      height={1276}
      src="/images/317311e1-4fac-487f-a30e-2aeeb6e0d30a.png"
      width={2046}
    />

    <Text>
      Give your Rule a name. Under "Actions", select "Set Groups in
      your-app-name". Then, set it to "For each role with value that matches
      your-role-name". Hit "Save".
    </Text>

    <Img
      className="my-8"
      height={1560}
      src="/images/02d19425-34e2-45b3-bb64-bc3962714493.png"
      width={2136}
    />

    <Text>
      Within your SCIM app under the Users tab, you may then need to click on
      any "Pending" notifications to confirm the update for users.
    </Text>

    <Img
      className="my-8"
      height={1244}
      src="/images/3aa55536-48e8-4322-9e5e-7227c4099f9c.png"
      width={2140}
    />
  </StepContent>
);

export const assignGroupsToYourApplication: StepData = {
  title: 'Assign Groups to your Application',
  component: Component,
};
