import React, { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { generateDsyncStepData } from '../../utils/generate-dsync-step-data';
import { selectJumpCloudApplication } from './1-select-jump-cloud-application';
import { configureJumpCloudScim } from './2-configure-jump-cloud-scim';
import { assignUserGroupsToYourApplication } from './3-assign-user-groups-to-your-application';
import { setUpAttributeMapping } from './4-set-up-attribute-mapping';

interface JumpCloudScimStepsProps {}

const stepsData: StepData[] = generateDsyncStepData([
  selectJumpCloudApplication,
  configureJumpCloudScim,
  assignUserGroupsToYourApplication,
  setUpAttributeMapping,
]);

export const JumpCloudScimSteps: FC<Readonly<JumpCloudScimStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(stepsData);
  const stepRoutes = useDsyncStepRoutes(enabledSteps);

  return (
    <DsyncStepNavigation stepsData={enabledSteps}>
      {stepRoutes}
    </DsyncStepNavigation>
  );
};
