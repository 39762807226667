import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MapAttributesStep } from '../../map-attributes-step/map-attributes-step';

const Component: FC = () => (
  <MapAttributesStep>
    <Text>
      There are additional attributes needed by the application for it to fully
      function. Note that basic attributes like a user's name or email are
      already mapped, and so are not present below.
    </Text>

    <Text>
      JumpCloud offers the following list of{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href={
          'https://support.jumpcloud.com/support/s/article/Custom-SCIM-Identity-Management#attributemappings'
        }
      >
        default attribute mappings
      </ExternalLink>{' '}
      in their documentation.
    </Text>

    <Text>
      Map attributes from your Directory Provider's responses to the attributes
      shown below.
    </Text>
  </MapAttributesStep>
);

export const setUpAttributeMapping: StepData = {
  title: 'Set up Attribute Mapping',
  customAttributesStep: true,
  component: Component,
};
