import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { motion } from 'framer-motion';
import * as React from 'react';
import { DirectoryType } from '../../../graphql/generated';
import { ProviderCard } from './provider-card';
import { ProviderSelectionProps, ProviderType } from './types';

/**
 * A dummy component that takes all of its state and setters
 * as props from the useProviderConfigurator hook
 */
export const ProviderSelection = <T extends DirectoryType | ProviderType>({
  providerSelectionState,
  setProviderSelectionState,
  providers,
  providerLabels,
  activeConfigurator,
  ...props
}: ProviderSelectionProps<T>) => {
  const { selectedProvider } = providerSelectionState;

  if (
    selectedProvider &&
    activeConfigurator &&
    providerSelectionState.isHandlingConfiguration
  ) {
    const ProviderConfigComponent = activeConfigurator.component;
    return (
      <ProviderConfigComponent
        providerSelectionState={providerSelectionState}
        setProviderSelectionState={setProviderSelectionState}
      />
    );
  }

  return (
    <RadioGroupPrimitive.Root
      onValueChange={(val: T) => {
        setProviderSelectionState({ selectedProvider: val });
      }}
      value={selectedProvider}
      {...props}
      asChild
      className="mb-4 grid grid-cols-4 gap-4"
      orientation="horizontal"
    >
      <motion.ul
        animate="show"
        className="mb-4 grid grid-cols-4 gap-4"
        initial="hidden"
        variants={listVariants}
      >
        {providers.map((provider) => (
          <ProviderCard
            key={provider}
            data-testid={'provider-item__' + provider}
            label={providerLabels?.[provider]}
            value={provider}
          />
        ))}
      </motion.ul>
    </RadioGroupPrimitive.Root>
  );
};

const listVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      delay: 0.2,
      staggerChildren: 0.05,
    },
  },
};
