import get from 'lodash.get';
import React, { PropsWithChildren, ReactElement, useState } from 'react';
import {
  AnyObject,
  RecordStateDispatcher,
} from '../../../hooks/use-record-state';
import {
  StepData,
  StepDataWithPath,
  StepNavigation,
  StepNavigationProps,
} from '../../../shared/step-navigation';
import {
  StepsFormData,
  StepsFormErrors,
} from '../../../shared/step-navigation/steps-store-provider';
import { useDsyncStore } from '../dsync-store-provider';
import { SwitchDirectoryModal } from '../switch-directory-modal';

interface DsyncStepNavigationProps<TFormData> {
  stepsData: StepData<TFormData>[];
}

interface DsyncStepNavigationEnhancerProps<TFormData> {
  children: (props: StepNavigationProps<TFormData>) => ReactElement;
  onClickSwitchProvider: () => void;
  stepsData: StepData<TFormData>[];
}

export const DsyncStepNavigationEnhancer = <TFormData,>({
  children,
  stepsData,
  onClickSwitchProvider: handleSwitchProvider,
}: DsyncStepNavigationEnhancerProps<TFormData>) => {
  const { directory, updateDirectory } = useDsyncStore();

  const stepsWithPath: StepDataWithPath<TFormData>[] = stepsData.map(
    (step, index) => ({
      ...step,
      path: `/dsync/configure/${index + 1}`,
    }),
  );

  const handleUpdateWithChanges = async (
    formData: StepsFormData<AnyObject>,
    setFormErrors: RecordStateDispatcher<StepsFormErrors<TFormData>>,
  ) => {
    const response = await updateDirectory({ id: directory.id, ...formData });

    // This is carried over from the old Admin Portal. Our Directory Sync errror
    // handling does not actually work. This is more of a signpost for later when
    // we do fix the error handling.
    const errors = get(response, [
      'errors',
      '0',
      'extensions',
      'exception',
      'response',
      'errors',
    ]);

    if (errors) {
      setFormErrors(errors);
    }

    if (response?.data?.portal_updateDirectory) {
      setFormErrors({});
    }
  };

  return children({
    onClickSwitchProvider: handleSwitchProvider,
    onUpdateWithChanges: handleUpdateWithChanges,
    provider: directory.type,
    stepsData: stepsWithPath,
    stepsUrlBase: '/dsync/configure',
  });
};

export const DsyncStepNavigation = <TFormData extends AnyObject>({
  children,
  stepsData,
}: PropsWithChildren<DsyncStepNavigationProps<TFormData>>) => {
  const [isSwitchProviderModalOpen, setIsSwitchProviderModalOpen] =
    useState(false);
  return (
    <DsyncStepNavigationEnhancer<TFormData>
      onClickSwitchProvider={() => setIsSwitchProviderModalOpen(true)}
      stepsData={stepsData}
    >
      {(stepNavigationProps) => (
        <StepNavigation<TFormData> {...stepNavigationProps}>
          {children}
          <SwitchDirectoryModal
            open={isSwitchProviderModalOpen}
            setOpen={setIsSwitchProviderModalOpen}
          />
        </StepNavigation>
      )}
    </DsyncStepNavigationEnhancer>
  );
};
