import { Button } from '@workos-inc/component-library';
import React, { VFC } from 'react';
import { Server } from 'react-feather';
import { EmptyState } from '../empty-state';

interface AuditLogsEmptyStateProps {
  onReset: () => void;
}

export const AuditLogsEmptyState: VFC<AuditLogsEmptyStateProps> = ({
  onReset: handleReset,
}) => (
  <EmptyState
    icon={
      <Server
        className="mb-4 h-10 w-10 text-gray-lightmode-300 dark:text-gray-darkmode-300"
        size={16}
      />
    }
    title="You don't have any audit log events."
  >
    <Button appearance="secondary" onClick={handleReset}>
      Clear Search Query
    </Button>
  </EmptyState>
);
