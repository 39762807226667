import {
  Button,
  Card,
  FormItem,
  Label,
  Pill,
  Text,
} from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ProviderLogo } from '../../../../components/provider-logo';
import { formatDate } from '../../../../utils/formatters';
import { useSsoStore } from '../../sso-store-provider';
import { getConnectionName } from '../../utils/get-connection-name';
import { testConnection } from '../../utils/test-connection';

interface ConnectionOverviewProps {}

export const ConnectionOverview: FC<Readonly<ConnectionOverviewProps>> = () => {
  const { connection } = useSsoStore();

  return (
    <Card>
      <Card.Header className="flex items-center justify-between">
        <Card.Title>{connection.name}</Card.Title>
        <ProviderLogo provider={connection.type} width="60px" />
      </Card.Header>

      <Card.Body className="flex flex-col gap-4">
        <FormItem isInline>
          <Label htmlFor="identityProvider">Identity Provider</Label>
          <Text as="span" id="identityProvider">
            {getConnectionName(connection.type)}
          </Text>
        </FormItem>

        <FormItem isInline>
          <Label htmlFor="state">State</Label>
          <Pill appearance="green" id="state" size="small">
            {connection.state}
          </Pill>
        </FormItem>

        <FormItem isInline>
          <Label htmlFor="createdAt">Created At</Label>
          <Text as="span" id="createdAt">
            {formatDate(connection.createdAt)}
          </Text>
        </FormItem>

        <FormItem isInline>
          <Label htmlFor="connectionDomains">Connected Domains</Label>
          {connection.connectionDomains.map(({ domain }) => (
            <Pill key={domain} className="mr-2" size="small">
              {domain}
            </Pill>
          ))}
        </FormItem>

        <Button
          appearance="secondary"
          className="self-start"
          data-testid="test-sso-connection-button"
          onClick={() => testConnection(connection.id)}
        >
          Test Single Sign-On
        </Button>
      </Card.Body>
    </Card>
  );
};
