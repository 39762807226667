import { DirectoryType } from '../graphql/generated';

export const supportedDirectoryTypes = [
  DirectoryType.AzureScimv2_0,
  DirectoryType.BambooHr,
  DirectoryType.BreatheHr,
  DirectoryType.CyberArkScimV2_0,
  DirectoryType.FourthHr,
  DirectoryType.OktaScimv2_0,
  DirectoryType.PeopleHr,
  DirectoryType.PingFederateScimV2_0,
  DirectoryType.Rippling,
  DirectoryType.RipplingScimV2_0,
  DirectoryType.GoogleWorkspace,
  DirectoryType.Hibob,
  DirectoryType.JumpCloudScimV2_0,
  DirectoryType.OneLoginScimV2_0,
  DirectoryType.Workday,
  DirectoryType.GenericScimv1_1,
  DirectoryType.GenericScimv2_0,
] as const;

export type SupportedDirectoryType = typeof supportedDirectoryTypes[number];
