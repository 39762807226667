import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AnyObject,
  RecordStateDispatcher,
  useRecordState,
} from '../../hooks/use-record-state';
import { StepDataWithPath } from './interfaces/step-data';
import { extractCurrentStepFromPathname } from './utils';

export type StepsFormData<TFormData> = TFormData;
export type StepsFormErrors<TFormData> = Partial<TFormData>;

type StepsStoreContext<TFormData extends AnyObject> = {
  currentStepNumber: number;
  formData: StepsFormData<TFormData>;
  formErrors: StepsFormErrors<TFormData>;
  handleInputChange: (event: {
    target: { name: string; value: string };
  }) => void;
  handleNextStep: () => Promise<void>;
  isLoadingNextStep: boolean;
  setFormData: RecordStateDispatcher<StepsFormData<TFormData>>;
  setFormErrors: RecordStateDispatcher<StepsFormErrors<TFormData>>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StepsContext = createContext<StepsStoreContext<any> | null>(null);

export const useStepsStore = <TFormData extends AnyObject>() => {
  const context = useContext<StepsStoreContext<TFormData> | null>(StepsContext);

  if (!context) {
    throw new Error('useStepsStore can only be used within StepsContext');
  }

  return context;
};

export interface StepsStoreProviderProps<TFormData> {
  onUpdateWithChanges: (
    formData: StepsFormData<TFormData>,
    setFormErrors: RecordStateDispatcher<StepsFormErrors<TFormData>>,
  ) => Promise<void>;
  stepsData: StepDataWithPath<TFormData>[];
  stepsUrlBase: string;
}

export const StepsStoreProvider = <TFormData extends AnyObject>({
  children,
  onUpdateWithChanges,
  stepsData,
  stepsUrlBase,
}: PropsWithChildren<StepsStoreProviderProps<TFormData>>) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const currentStepNumber = useMemo(
    () => extractCurrentStepFromPathname(stepsUrlBase, pathname),
    [pathname, stepsUrlBase],
  );

  const [formData, setFormData] = useRecordState<StepsFormData<TFormData>>({});
  const [formErrors, setFormErrors] = useRecordState<
    StepsFormErrors<TFormData>
  >({});
  const [isLoadingNextStep, setIsLoadingNextStep] = useState<boolean>(false);

  const handleInputChange = useCallback(
    (event: { target: { name: string; value: string } }) => {
      const payload = { [event.target.name]: event.target.value };

      setFormData({ ...formData, ...payload });
    },
    [formData, setFormData],
  );

  const handleNextStep = useCallback(async () => {
    setIsLoadingNextStep(true);

    const nextStepNumber = currentStepNumber + 1;
    const nextStep = stepsData[nextStepNumber - 1];
    const nextStepPath = `${stepsUrlBase}/${nextStepNumber}`;

    if (nextStep?.confirmationStep && Object.keys(formData).length > 0) {
      await onUpdateWithChanges(formData, setFormErrors);
    }

    setIsLoadingNextStep(false);
    navigate(nextStepPath);
  }, [
    currentStepNumber,
    formData,
    navigate,
    onUpdateWithChanges,
    setFormErrors,
    stepsData,
    stepsUrlBase,
  ]);

  const context = useMemo<StepsStoreContext<TFormData>>(
    () => ({
      currentStepNumber,
      handleInputChange,
      handleNextStep,
      isLoadingNextStep,
      formData,
      formErrors,
      setFormData,
      setFormErrors,
    }),
    [
      currentStepNumber,
      formData,
      formErrors,
      handleInputChange,
      handleNextStep,
      isLoadingNextStep,
      setFormData,
      setFormErrors,
    ],
  );

  return (
    <StepsContext.Provider value={context}>{children}</StepsContext.Provider>
  );
};
