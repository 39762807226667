import { Text, TextInput } from '@workos-inc/component-library';
import { motion } from 'framer-motion';
import React, { ChangeEvent } from 'react';
import { DirectoryType } from '../../../../../graphql/generated';
import { IProviderConfigurator } from '../../../../components/provider-selection';

export const googleWorkspaceConfigurator: IProviderConfigurator<DirectoryType> =
  {
    title: 'Add your domain',
    description:
      'Provide the primary domain associated with your Google account.',
    shouldSkipConfiguration: (configData) => !!configData.directoryDomain,
    component: ({
      setProviderSelectionState: setConfigData,
      providerSelectionState: configData,
    }) => (
      <motion.div
        animate={{ opacity: 1 }}
        className="flex w-full max-w-xl flex-col items-center"
        initial={{ opacity: 0 }}
        transition={{ delay: 0.1 }}
      >
        <TextInput
          className="mt-4"
          id="missing-domain-input"
          name="Missing Domain"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setConfigData({
              directoryDomainError: '',
              directoryDomain: event.currentTarget.value,
            });
          }}
          placeholder="example.com"
          value={configData.directoryDomain || ''}
        />

        {configData.directoryDomainError && (
          <div className="mt-4 self-start text-red-darken">
            <Text inheritColor as="p">
              {configData.directoryDomainError}
            </Text>
          </div>
        )}
      </motion.div>
    ),
  };
