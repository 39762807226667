import {
  AutocompleteDate,
  Text,
  TokenAutocomplete,
} from '@workos-inc/component-library';
import { mapUndefined } from '@workos-inc/standard';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Activity,
  Calendar,
  Crosshair,
  DownloadCloud,
  User,
} from 'react-feather';
import { useSearchParams } from 'react-router-dom';
import { graphql } from '../../../utils/graphql';
import {
  AuditLogsActionsDropdown,
  AuditLogsBreadcrumb,
  AuditLogsExportCsvModal,
} from '../audit-logs-table';
import { AuditLogsEmptyState } from '../audit-logs-table/audit-logs-empty-state';
import { AuditLogsTable } from '../audit-logs-table/audit-logs-table';
import { usePaginated } from '../hooks/use-paginated';
import { PageWrapper } from '../page-wrapper/page-wrapper';
import { Pagination } from '../pagination';

const ActorOptions = () => (
  <div
    className="w-full min-w-[120px] rounded px-3 py-2"
    onMouseDown={(event) => event.preventDefault()}
  >
    <Text as="p" multiline={false} size="small" weight="medium">
      Search actors by name
    </Text>
  </div>
);

export function AuditLogEvents() {
  const [exportModalVisibility, setExportModalVisibility] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const filters = useMemo(() => {
    const params: [string, string][] = [];
    for (const param of searchParams.entries()) {
      params.push(param);
    }
    return params;
  }, [searchParams]);

  const actionFilter = useMemo(
    () => filters.find((filter) => filter[0] === 'event')?.[1] || undefined,
    [filters],
  );
  const targetFilter = useMemo(() => {
    const targetString =
      filters.find((filter) => filter[0] === 'target')?.[1] ?? '';
    return targetString.split(',');
  }, [filters]);
  const actorFilter = useMemo(
    () => filters.find((filter) => filter[0] === 'actor')?.[1] || undefined,
    [filters],
  );
  const dateFilter = useMemo(() => {
    const dateString =
      filters.find((filter) => filter[0] === 'date')?.[1] ?? '';
    const [startDate, endDate] = dateString?.split(',');
    return {
      startDate: startDate ? new Date(startDate).toISOString() : undefined,
      endDate: endDate ? new Date(endDate).toISOString() : undefined,
    };
  }, [filters]);

  const searchTargets = useCallback(async (input: string) => {
    try {
      const {
        data: { portal_auditLogTargets: auditLogTargets },
      } = await graphql().auditLogTargets({
        target: input,
        limit: 20,
      });
      return auditLogTargets.data.map(({ target }) => target);
    } catch (err) {
      return [];
    }
  }, []);

  const searchEvents = useCallback(async (input: string) => {
    try {
      const {
        data: { portal_auditLogValidators: auditLogValidators },
      } = await graphql().auditLogValidators({
        limit: 20,
        action: input,
      });
      return auditLogValidators.data.map((validator) => validator.action);
    } catch (err) {
      return [];
    }
  }, []);

  const tokens = useMemo(
    () => [
      {
        id: 'actor',
        label: 'Actor',
        icon: User,
        customPopover: ActorOptions,
      },
      {
        id: 'event',
        label: 'Events',
        icon: Activity,
        search: searchEvents,
      },
      {
        id: 'target',
        label: 'Targets',
        icon: Crosshair,
        search: searchTargets,
      },
      {
        id: 'date',
        label: 'Dates',
        icon: Calendar,
        customInput: AutocompleteDate,
      },
    ],
    [searchEvents, searchTargets],
  );

  const [data, paginationProps] = usePaginated(
    ({ before, after }) =>
      graphql().auditLogEvents({
        before,
        after,
        actions: mapUndefined((value: string) => [value])(actionFilter),
        actors: mapUndefined((value: string) => [value])(actorFilter),
        startDate: dateFilter.startDate,
        endDate: dateFilter.endDate,
        targets: targetFilter,
        limit: 15,
      }),
    (data) => data.portal_auditLogEvents,
    { pushToSearchParams: true },
  );

  const events = data?.portal_auditLogEvents?.data || [];

  return (
    <PageWrapper>
      <div className="flex w-full flex-col gap-2">
        <AuditLogsBreadcrumb />

        <div className="flex items-center justify-between">
          <h2>Audit Logs</h2>

          <AuditLogsActionsDropdown>
            <AuditLogsActionsDropdown.Item
              icon={DownloadCloud}
              onClick={() => setExportModalVisibility(true)}
            >
              Export CSV
            </AuditLogsActionsDropdown.Item>
          </AuditLogsActionsDropdown>
        </div>
      </div>

      <div className="relative mb-2">
        <TokenAutocomplete
          filters={filters}
          onFiltersChange={(filters) => {
            const filterQuery = filters.reduce(
              (obj, [key, value]) => ({ ...obj, [key]: value }),
              {},
            );

            setSearchParams(filterQuery, { replace: true });
          }}
          tokens={tokens}
        />
      </div>

      <>
        <div className="mb-2">
          <AuditLogsTable events={events} isLoading={!data} />
        </div>

        <Pagination {...paginationProps} className="border-t-0 px-0 sm:px-0" />
      </>

      {data && !events.length && (
        // eslint-disable-next-line max-len
        <div className="flex min-h-[60vh] flex-col place-content-center place-items-center gap-2 overflow-hidden rounded ring-1 ring-gray-lightmode-200 dark:ring-gray-darkmode-200">
          <AuditLogsEmptyState
            onReset={() => setSearchParams({}, { replace: true })}
          />
        </div>
      )}

      <AuditLogsExportCsvModal
        filters={filters}
        onOpenChange={(open) => setExportModalVisibility(open)}
        open={exportModalVisibility}
      />
    </PageWrapper>
  );
}
