import { GraphQLResponse } from 'graphql-request/dist/types';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ConnectionFragment,
  UpdateConnectionMutation,
  UpdateConnectionMutationVariables,
} from '../../../../graphql/generated';
import { LoadingScreen } from '../../../components/loading-screen';
import {
  RecordStateDispatcher,
  useRecordState,
} from '../../../hooks/use-record-state';
import { graphql } from '../../../utils/graphql';
import { logError } from '../../../utils/logger';

export interface SsoStoreProviderProps {
  children: React.ReactNode;
  initialConnection?: ConnectionFragment;
}

type SsoStore = {
  connection: ConnectionFragment;
};

type SsoStoreContext = SsoStore & {
  setSsoStore: RecordStateDispatcher<SsoStore>;
  updateConnection: (
    args: UpdateConnectionMutationVariables,
  ) => Promise<GraphQLResponse<UpdateConnectionMutation> | undefined>;
};

const SsoContext = createContext<SsoStoreContext | null>(null);

export const useSsoStore = () => {
  const context = useContext(SsoContext);

  if (!context) {
    throw new Error('useSsoStore can only be used within SsoContext');
  }

  return context;
};

export const SsoStoreProvider: FC<Readonly<SsoStoreProviderProps>> = ({
  children,
  initialConnection,
}) => {
  const navigate = useNavigate();

  const [ssoStore, setSsoStore] = useRecordState<SsoStore>({
    connection: initialConnection,
  });

  const [loadingConnection, setLoadingConnection] = useState(
    !initialConnection,
  );

  useEffect(() => {
    const loadConnection = async () => {
      const { data } = await graphql().Connections();

      const [connection] =
        data?.portal_connections.data.filter(
          (connection) => connection.__typename === 'portal_Connection',
        ) ?? [];

      if (connection && connection.__typename === 'portal_Connection') {
        setSsoStore({
          connection,
        });
      }

      if (data) {
        setLoadingConnection(false);
      }
    };

    if (!initialConnection) {
      void loadConnection();
    }
  }, [initialConnection, setSsoStore]);

  const updateConnection = useCallback(
    async (
      args: UpdateConnectionMutationVariables,
    ): Promise<GraphQLResponse<UpdateConnectionMutation> | undefined> => {
      let response;

      try {
        response = await graphql().UpdateConnection(args);

        const result = response.data?.portal_updateConnection;

        switch (result?.__typename) {
          case 'Portal_ConnectionUpdated':
            setSsoStore({ connection: result.connection });
            break;
          case 'ConnectionNotFound':
            navigate('/not-found');
            break;
        }
      } catch (error) {
        logError(error);
      }

      return response;
    },
    [navigate, setSsoStore],
  );

  const context = useMemo<SsoStoreContext>(
    () => ({ ...ssoStore, setSsoStore, updateConnection }),
    [ssoStore, setSsoStore, updateConnection],
  );

  if (loadingConnection) {
    return <LoadingScreen />;
  }

  return <SsoContext.Provider value={context}>{children}</SsoContext.Provider>;
};
