import { ConnectionType } from '../../../../graphql/generated';

export const supportedConnectionTypes = [
  ConnectionType.Adfssaml,
  ConnectionType.AdpOidc,
  ConnectionType.Auth0Saml,
  ConnectionType.AzureSaml,
  ConnectionType.CasSaml,
  ConnectionType.ClassLinkSaml,
  ConnectionType.CloudflareSaml,
  ConnectionType.CyberArkSaml,
  ConnectionType.DuoSaml,
  ConnectionType.GenericOidc,
  ConnectionType.GenericSaml,
  ConnectionType.GoogleSaml,
  ConnectionType.JumpCloudSaml,
  ConnectionType.KeycloakSaml,
  ConnectionType.LastPassSaml,
  ConnectionType.MiniOrangeSaml,
  ConnectionType.NetIqSaml,
  ConnectionType.OktaSaml,
  ConnectionType.OneLoginSaml,
  ConnectionType.OracleSaml,
  ConnectionType.PingFederateSaml,
  ConnectionType.PingOneSaml,
  ConnectionType.RipplingSaml,
  ConnectionType.SalesforceSaml,
  ConnectionType.ShibbolethGenericSaml,
  ConnectionType.ShibbolethSaml,
  ConnectionType.SimpleSamlPhpSaml,
  ConnectionType.VMwareSaml,
] as const;

export type SupportedConnectionType = typeof supportedConnectionTypes[number];
