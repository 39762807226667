import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Topnav } from '../../../components/topnav';
import { DsyncStoreProvider } from '../dsync-store-provider';
import {
  ConfiguredDirectoryPage,
  DirectorySyncProviderSelectionPage,
} from '../pages';
import { DsyncSteps } from '../steps';
import { DsyncRedirector } from './dsync-redirector';

interface DsyncRouterProps {}
export const DsyncRouter: FC<Readonly<DsyncRouterProps>> = () => (
  <DsyncStoreProvider>
    <Topnav />

    <Routes>
      <Route element={<DsyncRedirector />} path="/">
        <Route element={<DirectorySyncProviderSelectionPage />} path="/new" />
        <Route element={<ConfiguredDirectoryPage />} path="/:directoryId" />
        <Route element={<DsyncSteps />} path="/configure/*" />

        {/* Legacy redirection from old Admin Portal URLs */}
        <Route
          element={<Navigate to="/:directoryId" />}
          path="/directory/:directoryId"
        />
      </Route>
    </Routes>
  </DsyncStoreProvider>
);
