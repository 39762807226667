import { Button, Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { AuditLogExportDetails } from '../../hooks/use-audit-log-export';

interface ErrorStateProps extends Pick<AuditLogExportDetails, 'error'> {
  onCancel: () => void;
}

export const ErrorState: FC<ErrorStateProps> = ({ error, onCancel }) => (
  <>
    <div>
      <Text as="h1" size="large" weight="medium">
        {error?.message ?? 'Something went wrong.'}
      </Text>
      <Text as="p" multiline={false} size="small">
        Try removing filters or increasing your date range.
      </Text>
    </div>

    <div className="flex gap-x-2">
      <Button appearance="secondary" onClick={() => onCancel()}>
        Change filters
      </Button>
    </div>
  </>
);
