import { Button, Dialog, InputGroup } from '@workos-inc/component-library';
import React, { FC, FormEvent, useState } from 'react';
import { AlertCircle } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { usePortalSession } from '../../../../components/portal-session-provider';
import { graphql } from '../../../../utils/graphql';
import { useDsyncStore } from '../../dsync-store-provider';

export interface DeleteDirectoryProps {}

export const DeleteDirectory: FC<Readonly<DeleteDirectoryProps>> = () => {
  const { appName } = usePortalSession();
  const { directory, setDsyncStore } = useDsyncStore();

  const navigate = useNavigate();

  const [displayDeletionModal, setDisplayDeletionModal] = useState(false);
  const [resetText, setResetText] = useState('');

  const handleDeleteDirectory = async (event: FormEvent) => {
    event.preventDefault();

    await graphql().DeleteDirectory({ id: directory.id });

    setDsyncStore({ directory: undefined });

    void navigate('/dsync/new');
  };

  return (
    <>
      <div className="w-full border-t border-t-gray-300 pt-4">
        <Button
          appearance="red"
          iconLeft={<AlertCircle size={16} />}
          onClick={() => setDisplayDeletionModal(true)}
        >
          Reset Directory
        </Button>
      </div>

      <Dialog
        isDangerous
        acceptButtonProps={{ type: 'submit', form: 'deleteDirectoryForm' }}
        acceptText="Reset Directory"
        description={`This action will reset any information and configuration associated
            with your directory. Your users of ${appName} will no longer be managed by the directory
            until the directory is setup again.`}
        isAcceptDisabled={resetText !== 'Reset'}
        onOpenChange={(open) => setDisplayDeletionModal(open)}
        open={displayDeletionModal}
        title="Reset Directory"
      >
        <form
          id="deleteDirectoryForm"
          onSubmit={(event) => handleDeleteDirectory(event)}
        >
          <InputGroup
            id="reset-directory-id"
            label="Type 'Reset' to reset the directory"
            name="reset"
            onChange={(event) => setResetText(event.target.value)}
            placeholder="Reset"
            value={resetText}
          />
        </form>
      </Dialog>
    </>
  );
};
