import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { NextStep } from '../../../components/next-step';
import { usePortalSession } from '../../../components/portal-session-provider';
import { useStepsStore } from '../../../shared/step-navigation/steps-store-provider';
import { useDsyncStore } from '../dsync-store-provider';
import { MapAttributes } from '../map-attributes';

export const MapAttributesStep: FC = ({ children }) => {
  const { appName } = usePortalSession();
  const { directory, directoryCustomAttributes } = useDsyncStore();
  const { handleNextStep } = useStepsStore();
  return (
    <>
      {children ? (
        children
      ) : (
        <>
          <Text>
            There are additional attributes needed by the application for it to
            fully function. Note that basic attributes like a user's name or
            email are already mapped, and so are not present below.
          </Text>

          <Text>
            Map attributes from your Directory Provider's responses to the
            attributes shown below.
          </Text>
        </>
      )}

      {directoryCustomAttributes.length && (
        <MapAttributes
          appName={appName}
          directory={directory}
          directoryCustomAttributes={directoryCustomAttributes}
          onFinishMapping={async () => {
            await handleNextStep();
          }}
          readonly={false}
        >
          {(buttonProps) => (
            <NextStep
              buttonText="Save custom attributes"
              label="I’ve finished configuring attribute mappings."
              {...buttonProps}
            />
          )}
        </MapAttributes>
      )}
    </>
  );
};
