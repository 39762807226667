import React, { FC, useEffect, useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { DirectoryState } from '../../../../graphql/generated';
import { useDsyncStore } from '../dsync-store-provider';

export const DsyncRedirector: FC = () => {
  const navigate = useNavigate();
  const onConfigurePage = useMatch('/dsync/configure/*');
  const onRootDsyncPage = useMatch('/dsync');
  const onNewPage = useMatch('/dsync/new');

  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { directory } = useDsyncStore();

  useEffect(() => {
    if (!isInitialLoad && !onRootDsyncPage) {
      return;
    }

    if (directory?.state) {
      if (directory?.state === DirectoryState.Linked) {
        navigate(`/dsync/${directory.id}`, { replace: true });
      } else if (!onConfigurePage) {
        navigate('/dsync/configure/1', { replace: true });
      }
    } else if (!onNewPage) {
      navigate('/dsync/new', { replace: true });
    }

    if (isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [
    directory?.state,
    directory?.id,
    isInitialLoad,
    onConfigurePage,
    onRootDsyncPage,
    onNewPage,
    navigate,
  ]);

  return <Outlet />;
};
