import { Toast, ToastAppearance } from '@workos-inc/component-library';
import classnames from 'classnames';
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from 'react';

interface showToastProps {
  appearance?: ToastAppearance;
  title: ReactNode;
  description?: ReactNode;
  icon?: ReactNode;
}

interface ContextValue {
  showToast: (props: showToastProps) => void;
}

export interface ToastProviderProps {
  children?: React.ReactNode;
}

export const ToastContext = createContext<ContextValue | null>(null);

export const ToastProvider: FC<Readonly<ToastProviderProps>> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [appearance, setAppearance] = useState<ToastAppearance>('green');
  const [title, setTitle] = useState<ReactNode>(null);
  const [description, setDescription] = useState<ReactNode>(null);
  const [icon, setIcon] = useState<ReactNode>(null);

  const showToast = ({
    appearance = 'green',
    title,
    description,
    icon,
  }: showToastProps) => {
    setTitle(title);
    setAppearance(appearance);
    setDescription(description);
    setIsOpen(true);
    setIcon(icon);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast.Provider>
        <Toast
          appearance={appearance}
          className={classnames({ 'flex items-start gap-2': icon })}
          onOpenChange={(isOpen) => setIsOpen(isOpen)}
          open={isOpen}
        >
          {icon && <Toast.Icon appearance={appearance}>{icon}</Toast.Icon>}
          <div>
            <Toast.Title>{title}</Toast.Title>
            {description && (
              <Toast.Description>{description}</Toast.Description>
            )}
          </div>
        </Toast>
        <Toast.Viewport />
      </Toast.Provider>
    </ToastContext.Provider>
  );
};

export const useToast = (): ContextValue => {
  const toastContext = useContext(ToastContext);
  if (!toastContext) {
    throw new TypeError(
      '`useToast` must be called from within an `ToastProvider`',
    );
  }
  return toastContext;
};
