import classNames from 'classnames';
import { motion } from 'framer-motion';
import Highlight, { defaultProps } from 'prism-react-renderer';
import React, { useMemo, useRef, VFC } from 'react';
import { PRISM_JSON_SCHEMA_THEME } from './prism-json-schema-theme';

const PRISM_JSON_LINE_HEIGHT = 20;

interface JsonSchemaProps {
  json: object;
  highlightedProperty?: string;
}

export const JsonSchema: VFC<JsonSchemaProps> = ({
  json,
  highlightedProperty,
}) => {
  const propertyLines = useRef<Record<string, number>>({});

  const highlightedLines = useMemo<[number, number] | undefined>(() => {
    if (highlightedProperty) {
      const property = highlightedProperty as keyof typeof json;
      const startLine = propertyLines.current[highlightedProperty];
      // if the property exists, we now need to count how many lines we need to highlight
      if (startLine && json[property]) {
        // if it's a string, it's one single line
        // but if it's object/array, we have to count all the nested lines
        // therefore we convert it back to string and split the lines
        // to count the amount of lines of this property
        const lineCount =
          typeof json[property] === 'string'
            ? 0
            : JSON.stringify(json[property], null, '  ').split('\n').length - 1;

        return [startLine, lineCount + 1];
      }
    }
    return undefined;
  }, [highlightedProperty, json]);

  return (
    <div className="relative w-fit">
      {highlightedLines && (
        <motion.div
          key={highlightedLines[0]}
          animate={{
            scaleY: 1,
            opacity: 1,
          }}
          className="absolute inset-x-0 top-0 bg-[#0091FF0A] ring-1 ring-[#0091FF]"
          initial={{
            scaleY: 0.75,
            opacity: 0,
            y: Math.floor(
              highlightedLines[0] * PRISM_JSON_LINE_HEIGHT +
                PRISM_JSON_LINE_HEIGHT,
            ),
            height: Math.ceil(highlightedLines[1] * PRISM_JSON_LINE_HEIGHT),
          }}
        />
      )}

      <Highlight
        {...defaultProps}
        code={JSON.stringify(json, null, '  ')}
        language="json"
        theme={PRISM_JSON_SCHEMA_THEME}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <pre
            className={classNames(
              className,
              'relative p-5 text-xs min-h-full font-mono',
            )}
            style={{
              ...style,
              lineHeight: `${PRISM_JSON_LINE_HEIGHT}px`,
            }}
          >
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line, key: i })}>
                {line.map((token, key) => {
                  const tokenProps = getTokenProps({ token, key });

                  // When it finds a property, store its line number so it can be highlighted later
                  if (token.types.includes('property')) {
                    propertyLines.current = {
                      ...propertyLines.current,
                      [cleanProperty(token.content)]: i,
                    };
                  }

                  return <span key={key} {...tokenProps} />;
                })}
              </div>
            ))}
          </pre>
        )}
      </Highlight>
    </div>
  );
};

const cleanProperty = (property: string) => property.replace(/\"/g, '');
