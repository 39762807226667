import React, { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { generateDsyncStepData } from '../../utils/generate-dsync-step-data';
import { createOktaApplication } from './1-create-okta-application';
import { configureOktaApiIntegration } from './2-configure-okta-api-integration';
import { setupAttributeMapping } from './3-setup-attribute-mapping';
import { configureProvisioningActions } from './4-configure-provisioning-actions';
import { assignPeopleGroupsToOktaApplication } from './5-assign-people-groups-to-okta-application';
import { pushGroups } from './6-push-groups';

interface OktaStepsProps {}

const stepsData: StepData[] = generateDsyncStepData([
  createOktaApplication,
  configureOktaApiIntegration,
  setupAttributeMapping,
  configureProvisioningActions,
  assignPeopleGroupsToOktaApplication,
  pushGroups,
]);

export const OktaSteps: FC<Readonly<OktaStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(stepsData);
  const stepRoutes = useDsyncStepRoutes(enabledSteps);

  return (
    <DsyncStepNavigation stepsData={enabledSteps}>
      {stepRoutes}
    </DsyncStepNavigation>
  );
};
