import React, { useEffect } from 'react';
import { ConnectionType, DirectoryType } from '../../../graphql/generated';
import { RequestState } from '../../features/dsync/interfaces/request-state';
import { useRecordState } from '../../hooks/use-record-state';
import { ProviderConfigurators, ProviderSelectionState } from './types';

export const useProviderSelection = <T extends DirectoryType | ConnectionType>({
  initialDirectoryDomain,
  initialProvider,
  requestState,
  onSubmit,
  providers,
  providerLabels,
  providerConfigurators,
}: {
  initialDirectoryDomain?: string;
  initialProvider?: T;
  requestState: RequestState;
  onSubmit: (data: ProviderSelectionState<T>) => void;
  providers: readonly T[];
  providerConfigurators?: ProviderConfigurators<T>;
  providerLabels?: { [key: string]: string };
}) => {
  const [providerSelectionState, setProviderSelectionState] = useRecordState<
    ProviderSelectionState<T>
  >({
    selectedProvider: initialProvider,
    directoryDomain: initialDirectoryDomain,
  });
  const selectedProviderConfigurator =
    providerConfigurators?.[providerSelectionState.selectedProvider as T];

  /** Catch duplicate_domain errors */
  useEffect(() => {
    if (
      requestState.type === 'failed' &&
      requestState.value.errorCode === 'duplicate_domain'
    ) {
      setProviderSelectionState({
        directoryDomainError: requestState.value.message,
      });
    }
  }, [requestState, setProviderSelectionState]);

  /**
   * if we are configuring a provider (like GoogleWorkspace)
   * this handler resets the state.
   */
  const resetProviderSelectionState = React.useCallback(() => {
    setProviderSelectionState({
      isHandlingConfiguration: false,
      selectedProvider: undefined,
      directoryDomain: initialDirectoryDomain,
      directoryDomainError: undefined,
    });
  }, [setProviderSelectionState, initialDirectoryDomain]);

  /**
   * Based on whether the provider has an configuration component
   * it renders that configuration component or submits the data
   * using the passed "onSubmit" handler
   */
  const handleSelectedProvider = React.useCallback(() => {
    if (!providerSelectionState.selectedProvider) {
      return;
    }
    if (
      selectedProviderConfigurator &&
      !providerSelectionState.isHandlingConfiguration &&
      !selectedProviderConfigurator.shouldSkipConfiguration?.(
        providerSelectionState,
      )
    ) {
      setProviderSelectionState({ isHandlingConfiguration: true });
    } else {
      onSubmit(providerSelectionState);
    }
  }, [
    selectedProviderConfigurator,
    providerSelectionState,
    setProviderSelectionState,
    onSubmit,
  ]);

  const activeConfigurator = providerSelectionState.isHandlingConfiguration
    ? selectedProviderConfigurator
    : undefined;

  return {
    resetProviderSelectionState,
    handleSelectedProvider,
    activeConfigurator,
    configData: providerSelectionState,
    providerSelectionProps: {
      activeConfigurator,
      providerSelectionState,
      setProviderSelectionState,
      providers,
      providerLabels,
    },
  };
};
