import { Text } from '@workos-inc/component-library';
import React, { FC, ReactNode } from 'react';

interface EmptyStateProps {
  title: string;
  children?: ReactNode;
  icon?: JSX.Element;
  minHeight?: number;
}

export const EmptyState: FC<Readonly<EmptyStateProps>> = ({
  icon,
  title,
  children,
  minHeight = 500,
}) => (
  <div
    className="flex flex-col items-center justify-center text-center"
    style={{ minHeight: `${minHeight}px` }}
  >
    {icon}
    <Text as="h3" className="mb-2" size="large">
      {title}
    </Text>
    {children}
  </div>
);
