import React, { FC } from 'react';
import { CenteredPageContent } from '../../../../components/centered-page-content';
import { ConnectionOverview } from './connection-overview';

interface ConfiguredSsoConnectionPageProps {}

export const ConfiguredSsoConnectionPage: FC<
  Readonly<ConfiguredSsoConnectionPageProps>
> = () => (
  <CenteredPageContent className="flex flex-col gap-8">
    <ConnectionOverview />
  </CenteredPageContent>
);
