import { Text } from '@workos-inc/component-library';
import { motion } from 'framer-motion';
import React from 'react';
import { Title } from '../typography';
import { PageTitle } from '../with-page-title/with-page-title';

type PageProps = {
  title?: React.ReactChild;
  description?: React.ReactChild;
  navTitle: string;
};
export const PageLayout: React.FC<PageProps> = ({
  title,
  navTitle,
  description,
  children,
}) => (
  <PageTitle title={navTitle}>
    <div className="mx-auto flex max-w-7xl flex-col items-center py-8">
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.1 }}
      >
        {title && typeof title === 'string' ? (
          <Title>{title}</Title>
        ) : (
          description
        )}
      </motion.div>
      <motion.div
        animate={{ opacity: 1 }}
        className="mt-1 mb-4 flex items-center gap-1"
        initial={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        {description && typeof description === 'string' ? (
          <Text as="p" size="medium">
            {description}
          </Text>
        ) : (
          description
        )}
      </motion.div>
      {children}
    </div>
  </PageTitle>
);
