import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';

interface UnsupportedProviderProps {}

export const UnsupportedProvider: FC<
  Readonly<UnsupportedProviderProps>
> = () => (
  <div className="mt-28 flex flex-col items-center">
    <Text as="h1" className="mb-4" size="xxlarge" weight="medium">
      Unsupported Provider
    </Text>

    <Text>This provider is not supported by the Admin Portal.</Text>
  </div>
);
