import React, { FC } from 'react';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { DsyncStepNavigation } from '../../dsync-step-navigation';
import { useDsyncStepRoutes } from '../../hooks/use-dsync-step-routes';
import { useEnabledDsyncSteps } from '../../hooks/use-enabled-dsync-steps';
import { generateDsyncStepData } from '../../utils/generate-dsync-step-data';
import { provideDirectoryInformation } from './1-provide-directory-information';
import { configureDirectoryProvider } from './2-configure-directory-provider';
import { setUpAttributeMapping } from './3-set-up-attribute-mapping';

interface GenericScimStepsProps {}

const stepsData: StepData[] = generateDsyncStepData([
  provideDirectoryInformation,
  configureDirectoryProvider,
  setUpAttributeMapping,
]);

export const GenericScimSteps: FC<Readonly<GenericScimStepsProps>> = () => {
  const enabledSteps = useEnabledDsyncSteps(stepsData);
  const stepRoutes = useDsyncStepRoutes(enabledSteps);

  return (
    <DsyncStepNavigation stepsData={enabledSteps}>
      {stepRoutes}
    </DsyncStepNavigation>
  );
};
