import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { Intent } from '../../../utils/token';
import { StepData } from '../interfaces/step-data';

export const createStepRoutesHookForIntent =
  (intent: Intent) =>
  <TFormData,>(stepData: StepData<TFormData>[]) => {
    const formattedStepData = stepData.map((step, index) => ({
      path: `/${index + 1}`,
      element: <step.component />,
    }));

    return useRoutes([
      ...formattedStepData,
      { path: '/', element: <Navigate to={`/${intent}/configure/1`} /> },
    ]);
  };
