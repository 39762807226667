import { unreachable } from '@workos-inc/standard';
import React, { FC, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { ConnectionType } from '../../../../graphql/generated';
import { PageTitle } from '../../../components/with-page-title';
import { useSsoStore } from '../sso-store-provider';
import { getConnectionName } from '../utils/get-connection-name';
import { getSupportedConnectionType } from '../utils/get-supported-connection-type';

interface SsoStepsProps {}

export const SsoSteps: FC<Readonly<SsoStepsProps>> = () => {
  const { connection } = useSsoStore();

  const connectionType = getSupportedConnectionType(connection);

  const steps = useCallback(() => {
    if (!connectionType) {
      return null;
    }

    switch (connectionType) {
      case ConnectionType.Adfssaml:
        return <div>Adfssaml</div>;
      case ConnectionType.AdpOidc:
        return <div>AdpOidc</div>;
      case ConnectionType.Auth0Saml:
        return <div>Auth0Saml</div>;
      case ConnectionType.AzureSaml:
        return <div>AzureSaml</div>;
      case ConnectionType.CasSaml:
        return <div>CasSaml</div>;
      case ConnectionType.ClassLinkSaml:
        return <div>ClassLinkSaml</div>;
      case ConnectionType.CloudflareSaml:
        return <div>CloudflareSaml</div>;
      case ConnectionType.CyberArkSaml:
        return <div>CyberArkSaml</div>;
      case ConnectionType.DuoSaml:
        return <div>DuoSaml</div>;
      case ConnectionType.GenericOidc:
        return <div>GenericOidc</div>;
      case ConnectionType.GenericSaml:
        return <div>GenericSaml</div>;
      case ConnectionType.GoogleSaml:
        return <div>GoogleSaml</div>;
      case ConnectionType.JumpCloudSaml:
        return <div>JumpCloudSaml</div>;
      case ConnectionType.KeycloakSaml:
        return <div>KeycloakSaml</div>;
      case ConnectionType.LastPassSaml:
        return <div>LastPassSaml</div>;
      case ConnectionType.MiniOrangeSaml:
        return <div>MiniOrangeSaml</div>;
      case ConnectionType.NetIqSaml:
        return <div>NetIqSaml</div>;
      case ConnectionType.OktaSaml:
        return <div>OktaSaml</div>;
      case ConnectionType.OneLoginSaml:
        return <div>OneLoginSaml</div>;
      case ConnectionType.OracleSaml:
        return <div>OracleSaml</div>;
      case ConnectionType.PingFederateSaml:
        return <div>PingFederateSaml</div>;
      case ConnectionType.PingOneSaml:
        return <div>PingOneSaml</div>;
      case ConnectionType.RipplingSaml:
        return <div>RipplingSaml</div>;
      case ConnectionType.SalesforceSaml:
        return <div>SalesforceSaml</div>;
      case ConnectionType.ShibbolethGenericSaml:
        return <div>ShibbolethGenericSaml</div>;
      case ConnectionType.ShibbolethSaml:
        return <div>ShibbolethSaml</div>;
      case ConnectionType.SimpleSamlPhpSaml:
        return <div>SimpleSamlPhpSaml</div>;
      case ConnectionType.VMwareSaml:
        return <div>VMwareSaml</div>;
      default:
        return unreachable(connectionType);
    }
  }, [connectionType]);

  if (!connectionType) {
    return <Navigate to="/unsupported-provider" />;
  }

  const connectionProviderName = getConnectionName(connectionType);

  return (
    <PageTitle title={`Configure SSO with ${connectionProviderName}`}>
      {steps()}
    </PageTitle>
  );
};
