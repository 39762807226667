import { Card, Pill, Table, Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { DirectoryUserFragment as DirectoryUser } from '../../../../../../graphql/generated';
import { getDirectoryUserStateBadge } from '../../../utils/directory-user-state-badges';
import { GroupsCell } from './directory-users-table-group-cell';

type DirectoryUsersTableProps = {
  users: DirectoryUser[];
};

export const DirectoryUsersTable: FC<Readonly<DirectoryUsersTableProps>> = ({
  users,
}) => (
  <Card>
    <Card.Header>
      <Card.Title>Users</Card.Title>
    </Card.Header>

    <Card.Body className="mt-4 p-0">
      {users.length ? (
        <Table
          columns={[
            {
              header: 'First Name',
              key: 'firstName',
              id: 'firstName',
            },
            {
              header: 'Last Name',
              key: 'lastName',
              id: 'lastName',
            },
            {
              header: 'Username',
              key: 'username',
              id: 'username',
            },
            {
              header: 'State',
              key: 'state',
              id: 'state',
              cell: ({ row }) => (
                <Pill
                  appearance={getDirectoryUserStateBadge(row.state)}
                  size="small"
                >
                  {row.state}
                </Pill>
              ),
            },
            {
              header: 'Groups',
              key: 'directoryGroups',
              id: 'directoryGroups',
              cell: ({ value, row: { id } }) =>
                value instanceof Array && value.length ? (
                  <GroupsCell groups={value} rowId={id} />
                ) : (
                  <>{'-'}</>
                ),
            },
          ]}
          data={users}
        />
      ) : (
        <div className="p-6 text-center">
          <Text as="p">There are no users for this directory.</Text>
        </div>
      )}
    </Card.Body>
  </Card>
);
