import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { Img } from '../../../../components/image';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MapAttributesStep } from '../../map-attributes-step/map-attributes-step';

const Component: FC = () => (
  <MapAttributesStep>
    <Text>
      There are additional attributes needed by your application for it to fully
      function. Please add and map Rippling attributes to their corresponding
      values.
    </Text>

    <Img
      className="my-8"
      height={1090}
      src="/images/16762d45-8db5-4a51-946a-ef479ee19a6e.png"
      width={1510}
    />
  </MapAttributesStep>
);

export const addSupportedScimAttributes: StepData = {
  title: 'Add Supported SCIM Attributes',
  customAttributesStep: true,
  component: Component,
};
