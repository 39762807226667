import React, { FC, RefObject } from 'react';

type OverflowPageProps = {
  children?: React.ReactNode;
  pageRef?: RefObject<HTMLDivElement>;
};

export const OverflowPage: FC<Readonly<OverflowPageProps>> = ({
  children,
  pageRef,
}) => (
  <div ref={pageRef} className="p-8">
    <div className="mx-auto max-w-7xl">{children}</div>
  </div>
);
