import React from 'react';

interface PageWrapperProps {
  children: React.ReactNode;
}

export const PageWrapper = ({ children }: PageWrapperProps) => (
  <div
    style={{
      // eslint-disable-next-line max-len
      // TODO: Apply proper styling for container https://linear.app/workos/issue/LOG-270/apply-proper-styling-for-container-element-on-audit-log-events-and
      maxWidth: '70%',
      margin: '0 auto',
      marginTop: 30,
    }}
  >
    {children}
  </div>
);
