import React, { FC } from 'react';
import { CenteredPageContent } from '../../../../components/centered-page-content';
import { DeleteDirectory } from './delete-directory';
import { DirectoryAttributeMappings } from './directory-attribute-mappings';
import { DirectoryOverview } from './directory-overview';
import { DirectoryUsers } from './directory-users';

interface ConfiguredDirectoryPageProps {}

export const ConfiguredDirectoryPage: FC<
  Readonly<ConfiguredDirectoryPageProps>
> = () => (
  <CenteredPageContent className="flex flex-col gap-8">
    <DirectoryOverview />
    <DirectoryAttributeMappings />
    <DirectoryUsers />
    <DeleteDirectory />
  </CenteredPageContent>
);
