import { Text } from '@workos-inc/component-library';
import React, { FC } from 'react';
import { ExternalLink } from '../../../../components/link';
import { StepData } from '../../../../shared/step-navigation/interfaces/step-data';
import { MapAttributesStep } from '../../map-attributes-step/map-attributes-step';

const Component: FC = () => (
  <MapAttributesStep>
    <Text>
      There are additional attributes needed by the application for it to fully
      function. Note that basic attributes like a user's name or email are
      already mapped, and so are not present below.
    </Text>

    <Text>
      The full list of available attributes from Breathe HR can be found{' '}
      <ExternalLink
        newTab
        appearance="highlight"
        href={
          'https://developer.breathehr.com/examples#!/employees/GET_version_employees_json'
        }
      >
        in their API documentation.{' '}
      </ExternalLink>
      You'll need to input your generated API key to see the above
      documentation.
    </Text>

    <Text>
      Map attributes from your Directory Provider's responses to the attributes
      shown below.
    </Text>
  </MapAttributesStep>
);

export const setUpAttributeMapping: StepData = {
  title: 'Set up Attribute Mapping',
  customAttributesStep: true,
  component: Component,
};
