import { useState } from 'react';
import { DirectoryType } from '../../../../graphql/generated';
import { EventName } from '../../../../utils/constants';
import { trackEvent } from '../../../../utils/track-event';
import { usePortalSession } from '../../../components/portal-session-provider';
import { ProviderSelectionState } from '../../../components/provider-selection/types';
import { useDsyncStore } from '../../../features/dsync/dsync-store-provider';
import { graphql } from '../../../utils/graphql';
import { RequestState } from '../interfaces/request-state';
import { parseDirectoryCreationError } from '../utils/parse-directory-creation-error';

/**
 * A hook that is used to switch the current directory type to something else
 */
export const useSwitchDirectory = () => {
  const [switchingState, setSwitchingState] = useState<RequestState>({
    type: 'idle',
  });
  const { directory, setDsyncStore } = useDsyncStore();
  const { organization, domains, setDomains } = usePortalSession();

  const switchDirectory = async (
    providerSwitchingData: ProviderSelectionState<DirectoryType>,
  ): Promise<void> => {
    const { selectedProvider, directoryDomain } = providerSwitchingData;
    if (!selectedProvider || !organization) {
      return;
    }
    setSwitchingState({ type: 'waiting' });
    try {
      await graphql().DeleteDirectory({ id: directory.id });
      const response = await graphql().CreateDirectory({
        name: organization.name,
        type: selectedProvider,
        domain: directoryDomain || domains[0],
      });
      const data = response?.data?.portal_createDirectory;
      if (data) {
        trackEvent(EventName.DirectoryProviderSwitched, {
          from_connection_type: directory.type,
          to_connection_type: selectedProvider,
        });
        setDsyncStore({ directory: data });
        if (directoryDomain) {
          setDomains([...new Set([...domains, directoryDomain])]);
        }
      }
    } catch (error) {
      const { errorCode, message } = parseDirectoryCreationError(
        error,
        directoryDomain,
      );
      setSwitchingState({
        type: 'failed',
        value: { errorCode, error, message },
      });
    }
  };

  return [switchingState, switchDirectory] as const;
};
